import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, '太短了!')
    .max(50, '太长了!')
    .required('名字是必填项!'),
  email: Yup.string()
    .email('请输入正确的邮箱地址!')
    .required('邮箱是必填项!'),
  message: Yup.string()
    .required('内容是必填项!'),
})

export default validationSchema
